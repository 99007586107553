@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap');
@import '../../../styles/scss/variables';

.nav-link {
    z-index: 2;
}

.sidebarNavigation {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 3.71vw;
    border-radius: 0;
    box-sizing: initial;
    border-left: 0.256vw solid var(--main-color);
    background: var(--main-color);
    transition: width 0.5s;
    z-index: 99;
    overflow-x: hidden;
}

.sidebarNavigation.active {
    width: 18.1vw;
}

.sidebar-list {
    display: flex;
    align-items: center;
}

.sidebarNavigation ul {
    top: 0;
    left: 0;
    width: calc(100%-0.265vw);
    padding-left: 0.265vw;
    padding-top: 6.12vw;
}

.sidebarNavigation ul li {
    position: relative;
    list-style: none;
    width: 100%;
    border-top-left-radius: 1.06vw;
    border-bottom-left-radius: 1.06vw;
}

.sidebarNavigation ul li.active {
    background: #fff;
}

.sidebarNavigation ul li b:nth-child(2)::before,
.sidebarNavigation ul li b:nth-child(1)::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--main-color);
}

.sidebarNavigation ul li a {
    position: relative;
    width: 100%;
    display: flex;
    text-decoration: none;
    color: #fff;
    user-select: none;
}

.sidebarNavigation b {
    user-select: none;
}

.sidebar-list__container {
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    cursor: pointer;
}

.sidebarNavigation ul li.active a {
    color: rgb(24, 24, 24);
}

.sidebarNavigation ul li a .sidebar-icon {
    position: relative;
    display: block;
    min-width: 3.18vw;
    height: 3.18vw;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ionIcon {
    font-size: 1.2vw;
}

.sidebarNavigation ul li a .sidebar-title {
    position: relative;
    display: block;
    padding-left: 0.53vw;
    white-space: normal;
    font-size: 1vw;
    font-weight: 500;
    white-space: nowrap;
}

.sidebarHeaderContainer {
    position: relative;
    display: flex;
    justify-content: start;
}

.active .sidebarIcon img {
    position: absolute;
    left: 4.295vw;
    top: 0.795vw;
}

.sidebarIcon img {
    position: absolute;
    top: 0.795vw;
    left: -9.795vw;
    transition: var(--logo-transition);
}

.sidebarIcon img {
    height: 7vw;
}

.toggle {
    top: 0.795vw;
    width: 2.1vw;
    height: 2.1vw;
    background: #ffffff;
    border-radius: 0.53vw;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: 0.5s;
    margin-left: 0.7vw;
    z-index: 11;
}

.toggle.active {
    /* left: calc(100% - 25px);  */
    background: white;
    margin-left: 14.7vw;
}


.toggle .ionIcon {
    position: absolute;
    color: #fff;
    font-size: 1.802vw;
    display: none;
}

.toggle .ionIcon.open,
.toggle.active .ionIcon.close {
    display: block;
}

.toggle .ionIcon.close,
.toggle.active .ionIcon.open {
    display: none;

}

.toggleButton {
    font-size: 1.7vw;
}


@media(max-width: 1281px) {
    .sidebarNavigation ul li a .sidebar-title {
        font-size: 1.2vw;
    }
}


@media (max-width: 770px) {
    .active .sidebarIcon img {
        top: 1.5vw;
        /* top: 5px; */
        left: 25.795vw;
    }

    .sidebarIcon img {
        height: 20vw;
        /* max-height: 75px; */
    }

    .toggleButton {
        font-size: 7.7vw;
    }

    .toggle {
        background: var(--main-color);
        color: #fff;
        /* top: 10px; */
        width: 10.1vw;
        /* width: 36px; */
        height: 10.1vw;
        /* height: 36px; */
        border-radius: 2.53vw;
        position: fixed;
        opacity: 0.9;
        left: 20px;
    }

    .toggle.active {
        margin-left: 63%;
        /* left: calc(var(--sidebar-width-active) - 36px);  */
        background: #fff;
        color: #000;
        opacity: 1;
    }



    .sidebarNavigation ul li {
        border-top-left-radius: 5.06vw;
        border-bottom-left-radius: 5.06vw;
    }

    .sidebar-list__container {
        padding-left: 5vw;
        /* padding-left: 22px; */
    }

    .sidebarNavigation ul li a .sidebar-title {
        padding-left: 5.53vw;
        /* padding-left: 22px; */
    }

    .sidebarNavigation {
        padding-top: 12.5vw;
        width: 0;
    }

    .sidebarNavigation.active {
        width: 80vw;
        overflow: auto;
    }

    .sidebarNavigation ul li a {
        display: flex;
        align-items: center;
    }

    .ionIcon {
        font-size: 4.7vw;
    }

    .sidebarNavigation ul li a .sidebar-title {
        font-size: 4.7vw;
    }

    .sidebarNavigation ul li a .sidebar-icon {
        height: 15.6vw;
        /* height: 50px; */
        display: flex;
        align-items: center;
    }

    .sidebarNavigation ul {
        padding-left: 4.265vw;
        /* padding-left: 15px; */
        padding-top: 8.12vw;
        /* padding-top: 30px; */
    }

    .sidebar-list {
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
        -webkit-tap-highlight-color: transparent;
        position: relative;
        /* left: 0.5%; */
    }

}