.events__create__style li {
    display: flex;
    list-style: none;
    margin-bottom: 1vw;
}

.li__style {
    margin-bottom: 2vw !important;
}

.button {
    width: auto;
    padding: 0 10px;
}
.buttonContainer {
    display: flex;
    justify-content: center;
    align-items: end;
    width: 100%;
    gap: 20px;
}

.events__create__style span {
    font-size: 0.9vw;
    font-family: "Roboto";
}

.events__create__style {
    width: 100%;
    min-height: 85vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.text__style {
    min-width: 7vw;
    height: 2vw;
    display: flex;
    align-items: center;
    margin-right: 1vw;
}

.events__create__style li {
    display: flex;
    justify-content: space-between;
}

.h3__style {
    font-size: 1.2vw;
    font-family: "Roboto";
    font-weight: 500;
    display: flex;
    justify-content: center;
    margin-bottom: 7vw;
}

@media (max-width: 1280px) {
    .h3__style {
        font-size: 3.2vw;
        margin-bottom: 5vw;
    }

    .events__create__style span {
        font-size: 1.35vw;
    }
}

@media (max-width: 770px) {
    .h3__style {
        font-size: 3.2vw;
        margin-bottom: 5vw;
    }

    .events__create__style span {
        font-size: 1.35vw;
    }

    .h3__style {
        font-size: 4vw;
        font-family: "Roboto";
        font-weight: 500;
        display: flex;
        justify-content: center;
        margin-bottom: 7vw;
    }

    .events__create__style ul {
        padding: 0;
        width: 100%;
        /* border: 2px red solid; */
    }

    .events__create__style li {
        display: flex;
        list-style: none;
        margin-bottom: 1vw;
    }

    .events__create__style {
        width: 100%;
        min-height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}