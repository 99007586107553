.board {
    width: 100%;
    height: auto;
    /* border: 2px red solid; */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.board span{
    font-size: 0.9vw;
    color: #aeaeae;
    margin-bottom: 1vw;
}

.board table {
    border: 0.3vw solid var(--main-color);
    border-radius: 1vw;
    padding: 1vw;
    width: 70%;
    border-spacing: 0; /* Add this to remove space between cells */
}

.board th {
    width: 30%;
    font-size: 1vw;
    /* border: 2px red solid; */
}

.board td {
    width: 30%;
    text-align: center;
    font-size: 0.9vw;
    padding: 0.5vw 0;
}

/* Add alternating row colors */
.board tr:nth-child(even) {
    background-color: rgb(245, 245, 245); /* Light gray for even rows */
}

.board tr:nth-child(odd) {
    background-color: white; /* White for odd rows */

}

@media (max-width: 767px) {

    .board table {
        border: 1vw solid var(--main-color);
        border-radius: 2vw;
        padding: 3vw;
        width: 100%;
    }
    
    .board th {
        width: 30%;
        font-size: 4vw;
        /* border: 2px red solid; */
    }
    
    .board td {
        width: 30%;
        text-align: center;
        font-size: 3vw;
        padding: 2vw 0;
    }

    .board span{
        font-size: 3vw;
        margin-bottom: 3vw;
    }
}
