@font-face {
    font-family: "Rob";
    src: url('assets/fonts/rob.ttf') format('ttf');
}

.text__style {
    white-space: nowrap;
    overflow: hidden;
    padding: 0.5vw;
    text-overflow: ellipsis;
    font-size: 1vw;
    font-family: 'Roboto';

    /*исправить шрифт ( не видет)*/
}

@media(max-width: 3841px) {}

@media(max-width: 2561px) {}

@media(max-width: 1921px) {}


@media (max-width: 770px) {
    .text__style {
        white-space: pre-wrap;
        padding: 0 2vw;
        font-size: 3.5vw;
        font-family: 'Roboto';


        /*исправить шрифт ( не видет)*/
    }
}


@media (max-width: 992px) {}


@media (max-width: 767px) {}


@media (max-width: 650) {}


@media (max-width: 480px) {}