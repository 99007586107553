body {
    font-family: 'Roboto', sans-serif;
}

i {
    margin-right: 0.53vw;
}

.navbar-logo {
    padding: 0.795vw;
    color: #fff;
    pointer-events: none;
}

.navbar-mainbg {
    background-color: var(--main-color);
    padding: 0px;
}

#navbarSupportedContent {
    overflow: hidden;
    position: relative;

}

#navbarSupportedContent ul {
    padding: 0px;
    margin: 0px;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}

#navbarSupportedContent ul li a i {
    margin-right: 0.53vw;
}

#navbarSupportedContent li {
    list-style-type: none;
    float: left;
}

#navbarSupportedContent ul li a {
    color: white;
    text-decoration: none;
    font-size: 0.795vw;
    display: block;
    padding: 1.06vw 1.06vw;
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
    position: relative;
}


#navbarSupportedContent>ul>li.active>a {
    color: var(--main-color);
    background-color: transparent;
    transition: all 0.35s;
}

#navbarSupportedContent a:not(:only-child):after {
    content: '\f105';
    position: absolute;
    right: 1.06vw;
    top: 0.53vw;
    font-size: 0.742vw;
    font-family: 'Font Awesome 5 Free';
    display: inline-block;
    padding-right: 0.159vw;
    vertical-align: middle;
    font-weight: 900;
    transition: 0.25s;
}

#navbarSupportedContent .active>a:not(:only-child):after {
    transform: rotate(90deg);
}

.hori-selector {
    display: inline-block;
    position: absolute;
    height: 100%;
    top: 0px;
    left: 0px;
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
    background-color: #fff;
    border-top-left-radius: 0.795vw;
    border-top-right-radius: 0.795vw;
    margin-top: 0.53vw;
}

.hori-selector .right,
.hori-selector .left {
    position: absolute;
    width: 1.325vw;
    height: 1.325vw;
    background-color: #fff;
    bottom: 0.4vw;
}

.hori-selector .right {
    right: -1.325vw;
}

.hori-selector .left {
    left: -1.325vw;
}

.hori-selector .right:before,
.hori-selector .left:before {
    content: '';
    position: absolute;
    width: 2.65vw;
    height: 2.65vw;
    border-radius: 50%;
    background-color: var(--main-color);
}

.hori-selector .right:before {
    bottom: 0;
    right: -1.325vw;
}

.hori-selector .left:before {
    bottom: 0;
    left: -1.325vw;
}

@media (max-width: 770px) {

    .navbar-mainbg {
        background-color: var(--main-color);
        padding: 0px;
        /* border: 2px red solid; */
        height: 12vw;
    }

    #navbarSupportedContent li {
        list-style-type: none;
        float: left;
        /* border: 2px violet solid; */
        height: 12vw;
        width: 14vw;
        /* border: 2px violet solid; */
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
        -webkit-tap-highlight-color: transparent;
    }

    #navbarSupportedContent ul li a {
        color: white;
        text-decoration: none;
        font-size: 2vw;
        display: block;
        padding: 1.06vw 1.06vw;
        transition-duration: 0.3s;
        transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
        position: relative;
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
        -webkit-tap-highlight-color: transparent;
    }

    .admin-navbar-icon {
        /* border: 2px red solid; */
        width: 6vw;
        height: 6vw;
        fill: white;
        border-color: red !important;
        /* background-color: white; */
        color: white;
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
        -webkit-tap-highlight-color: transparent;


    }

    .nav-item {
        /* border: green 2px solid; */
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .active .admin-navbar-icon {
        fill: var(--main-color);
        color: var(--main-color);
    }




    /* #navbarSupportedContent ul li a {
        padding: 0.636vw 1.59vw;
    }

    .hori-selector {
        margin-top: 0px;
        margin-left: 0.53vw;
        border-radius: 0;
        border-top-left-radius: 1.325vw;
        border-bottom-left-radius: 1.325vw;
    }

    .hori-selector .left,
    .hori-selector .right {
        right: 0.53vw;
    }

    .hori-selector .left {
        top: -1.325vw;
        left: auto;
    }

    .hori-selector .right {
        bottom: -1.325vw;
    }

    .hori-selector .left:before {
        left: -1.325vw;
        top: -1.325vw;
    }

    .hori-selector .right:before {
        bottom: -1.325vw;
        left: -1.325vw;
    } */
}