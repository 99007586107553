.occasion {
    width: 100%;
    height: 2.5vw;
    padding: 0.6vw 2.4vw 0.6vw 4.8vw;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    flex-direction: row;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.occasion div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.occasion__title {
    width: 30%;
    /* border: 2px red solid; */
    color: #242424;
    margin-right: 1vw;
}

.occasion__title span {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    padding: 0 1vw;
    text-overflow: ellipsis;
    font-size: 1vw;
    font-family: 'Roboto';
}

.occasion__date {
    width: 15%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #242424;
    /* border: 2px orange solid; */
}

.occasion__date span {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    padding: 0 1vw;
    text-overflow: ellipsis;
    font-size: 1vw;
    font-family: 'Roboto';
    width: 100%;
    /* border: 2px green solid; */
}

.occasion__time {
    width: 15%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #242424;
    /* border: 2px green solid; */
}

.occasion__time span {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    padding: 0 1vw;
    text-overflow: ellipsis;
    font-size: 1vw;
    font-family: 'Roboto';
}

.occasion__place {
    width: 40%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #242424;
    margin-right: 1vw;
    /* border: 2px green solid; */
}

.occasion__place span {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    padding: 0 1vw;
    text-overflow: ellipsis;
    font-size: 1vw;
    font-family: 'Roboto';
}

.occasion__buttin {
    display: grid;
    gap: 0 2vw;
    width: 25%;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: end;
    color: #242424;
}

.line {
    height: 2vw;
    width: 0.05vw;
    margin: 0 0.1vw;
    background-color: #eeeeee;
}

@media (max-width: 770px) {
    .occasion {
        padding: 0;
        text-align: center;
        width: 100%;

        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        /* border: 2px yellow dashed; */
    }


    .occasion__title a {
        font-size: 4.5vw !important;
    }

    .occasion__title {
        padding: 0;
        margin-top: 4vw;
        text-align: center;
        display: flex;
        justify-content: center;
        width: 100%;
        height: auto;
        /* border: 2px red solid; */
    }

    .occasion__date {
        padding: 0;
        text-align: center;
        width: 100%;
        height: auto;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        color: #242424;
        /* border: 2px blue solid; */
    }

    .occasion__time {
        padding: 0;
        text-align: center;
        width: 100%;
        height: auto;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        color: #242424;
        /* border: 2px orange solid; */
    }

    .occasion__place {
        width: 100%;
        text-align: center;
        width: 100%;
        height: auto;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        /* border: 2px green solid; */
    }

    .occasion__buttin {
        padding: 0;
        gap: 0 12vw;
        width: 100%;
        height: auto;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: center;
        color: #242424;
        /* border: 2px black solid; */
        margin: 4vw 0;

    }

    .line {
        height: 0.1vw;
        width: 35%;
        background-color: #eeeeee;
        margin: 2vw 0;
    }

    .occasion__place span {
        font-size: 3.5vw;
        padding: 0 5vw;
    }

    .occasion__time span {
        font-size: 3.5vw;
    }

    .occasion__date span {
        font-size: 3.5vw;
    }

    .occasion__title span {
        font-size: 4vw;
        padding: 0 3vw;

    }
}



@media (max-width: 992px) {}


@media (max-width: 767px) {}


@media (max-width: 650) {}


@media (max-width: 480px) {}