.main__block__style__about h3 {
    font-size: 1.8vw;
    font-family: "Roboto";
    display: flex;
    justify-content: center;
    margin-bottom: 2vw;
    color: var(--main-color);
    font-weight: 600;
    text-decoration-skip-ink: none;
    text-decoration: underline;
    text-decoration-style: wavy;
    text-decoration-thickness: 0.1vw;
    text-decoration-color: var(--main-color);
}

/* ------------------First block------------------ */
.first__block__about {
    /* border: 2px red solid; */
    display: flex;
    justify-content: space-evenly;
}

.date__time__place__style {
    border: solid 0.2vw var(--main-color-border);
    width: 80%;
    min-height: 8vw;
    /* height: auto; */
    box-shadow: 0px 0px 0.3vw 0px #00000040;
    border-radius: 1vw;
    background-color: white;
    padding: 1vw;

}

.date__time__place__style ul {
    margin: 0;
    padding: 0;
}

.date__time__place__style li {
    list-style: none;
    margin-bottom: 1vw;
}

.date__time__place__style li span {
    font-size: 0.85vw;
    font-family: "Roboto";

}



.description__style {
    border: solid 0.2vw var(--main-color-border);
    width: 80%;
    min-height: 8vw;
    /* height: auto; */
    box-shadow: 0px 0px 0.3vw 0px #00000040;
    border-radius: 1vw;
    background-color: white;
    padding: 1vw;

}

.description__style span {
    /* border: 2px red solid; */
    width: 100%;
    font-size: 0.85vw;
    font-family: "Roboto";
    line-height: 1.1vw;
    white-space: pre-wrap;
}

.up__text__style {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    /* border: 2px red solid; */
}

.text__style {
    font-size: 0.9vw;
    font-family: "Roboto";
    font-weight: 500;
    margin-bottom: 1vw;
}

/* ----------------------Second block------------------------- */


.second__block__about {
    display: flex;
    justify-content: space-evenly;
    margin-top: 4vw;
}

.list__participants__style {
    border: solid 0.2vw var(--main-color-border);
    width: 80%;
    height: 10vw;
    /* height: auto; */
    box-shadow: 0px 0px 0.3vw 0px #00000040;
    border-radius: 1vw;
    background-color: white;
    padding: 1vw;
    overflow: auto;
}

/* .list__participants__style::-webkit-scrollbar {
    display: none !important;
} */

.list__participants__style ul {
    margin: 0;
    padding: 0;
}


.list__participants__style li {
    list-style: none;
    margin-bottom: 0.5vw;
    font-size: 0.85vw;
    font-family: "Roboto";
}

.time__management__style {
    border: solid 0.2vw var(--main-color-border);
    width: 80%;
    min-height: 10vw;
    /* height: auto; */
    box-shadow: 0px 0px 0.3vw 0px #00000040;
    border-radius: 1vw;
    background-color: white;
    padding: 1vw;
    overflow: auto;
}

.time__management__style span {
    font-size: 0.85vw;
    font-family: "Roboto";
}

/* ------------------------Third block------------------------------------ */
.third__block__about {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 4vw;
    width: 100%;
}

.document__style a {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
}

.document__style a span {
    font-size: 0.75vw;
    font-family: "Roboto";
    color: #000000;
    width: 100%;
    text-align: center;
}

.document__style a span:hover {
    color: #a0a0a0;
}

.icon__style {
    width: 3vw;
    height: 3vw;
}

.docum__text__style {
    font-size: 0.9vw;
    font-family: "Roboto";
    text-align: center;
    margin-bottom: 1vw;
}

.doc__main__block {
    display: flex;
    justify-content: space-evenly;
    /* border: 2px red solid; */
    width: 100%;
}

/* -------------------Map style-------------------------------------------------- */

.block__map {
    display: flex;
    align-items: center;
    margin-top: 4vw;
    flex-direction: column;

}

.block__map span {
    font-size: 0.9vw;
    font-family: "Roboto";
    margin-bottom: 1vw;
}

.map__style {
    width: 60vw;
    height: 25vw;
    border-radius: 2vw;
    box-shadow: 0px 0px 0.3vw 0px #00000040;
    justify-content: center;
    border: solid 0.2vw var(--main-color-border);
}

/* ------------------Button style----------------------------------------------- */

.button__style {
    display: flex;
    justify-content: center;
    margin-top: 1vw;
}


/* -----------------Fxed height---------------------------- */
.border__for__list {
    width: 80%;
    height: 12vw;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

/* Logo стили */

.about__logo {
    margin: 3vw 0;

    border: 2px #fff solid;
}

.about__logo__img {
    width: 7vw;
    margin: 0 4vw;
}


@media(max-width: 770px) {

    .main__block__style__about h3 {
        font-size: 6.5vw;
        font-family: "Roboto";
        font-weight: 600;
        display: flex;
        justify-content: center;
        margin-bottom: 5vw;
        text-decoration-thickness: 0.1vw;
        margin-top: 0;
    }

    /* -----------------Fxed height---------------------------- */
    .border__for__list {
        width: 100%;
        height: 50vw;
        display: flex;
        flex-direction: column;
        overflow: auto;
        /* border: 2px red solid; */
    }

    /* ------------------First block------------------ */

    .first__block__about {
        /* border: 2px red solid; */
        display: flex;
        flex-direction: column;
        justify-content: none;
    }

    .date__time__place__style {

        width: 93%;
        height: auto;
        /* height: auto; */
        box-shadow: 0px 0px 3vw -1vw #00000040;
        border: 0.9vw solid var(--main-color);
        border-radius: 4vw;
        background-color: white;
        padding: 3vw;


    }

    .date__time__place__style ul {
        margin: 0;
        padding: 0;
    }

    .date__time__place__style li {
        list-style: none;
        margin-bottom: 1vw;
    }

    .date__time__place__style li span {
        font-size: 3.5vw;
        font-family: "Roboto";

    }



    .description__style {

        width: 93%;
        height: auto;
        /* height: auto; */
        box-shadow: 0px 0px 3vw -1vw #00000040;
        border: 0.9vw solid var(--main-color);
        border-radius: 4vw;
        background-color: white;
        padding: 3vw;
    }

    .description__style span {
        /* border: 2px red solid; */
        width: 100%;
        font-size: 3.5vw;
        font-family: "Roboto";
        line-height: 1.1vw;
    }

    .up__text__style {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-bottom: 7vw;
        /* border: 2px red solid; */
    }

    .text__style {
        font-size: 4vw;
        font-family: "Roboto";
        font-weight: 500;
        margin-bottom: 3vw;
    }

    /* ----------------------Second block------------------------- */

    .second__block__about {
        display: flex;
        flex-direction: column;
        justify-content: none;
        margin-top: 0vw;

    }

    .list__participants__style {

        width: 93%;
        height: 50vw;
        /* height: auto; */
        box-shadow: 0px 0px 3vw -1vw #00000040;
        border: 0.9vw solid var(--main-color);
        border-radius: 4vw;
        background-color: white;
        padding: 3vw;
        overflow: auto;
    }

    .list__participants__style ul {
        margin: 0;
        padding: 0;
    }


    .list__participants__style li {
        list-style: none;
        margin-bottom: 2vw;
        font-size: 3.5vw;
        font-family: "Roboto";
    }

    .time__management__style {

        width: 93%;
        height: auto;
        /* height: auto; */
        box-shadow: 0px 0px 3vw -1vw #00000040;
        border: 0.9vw solid var(--main-color);
        border-radius: 4vw;
        background-color: white;
        padding: 3vw;
        overflow: auto;

    }

    .time__management__style span {
        font-size: 3.5vw;
        font-family: "Roboto";

    }

    /* ------------------------Third block------------------------------------ */
    .third__block__about {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 0vw;
        width: 100%;

    }

    .document__style {
        margin-bottom: 3vw;
        width: 100%;

    }

    .document__style a {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-decoration: none;
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
        -webkit-tap-highlight-color: transparent;
    }

    .document__style a span {
        /* border: 2px red solid; */
        font-size: 3.5vw;
        font-family: "Roboto";
        color: #000000;
        width: 100%;
        text-align: center;
    }

    .document__style a span:hover {
        color: #a0a0a0;
    }

    .icon__style {
        width: 15vw;
        height: 15vw;
    }

    .docum__text__style {
        font-size: 4vw;
        font-family: "Roboto";
        text-align: center;
        margin-bottom: 3vw;
    }

    .doc__main__block {
        display: flex;
        flex-direction: row;
        justify-content: none;
        /* border: 2px red solid; */
        width: 100%;

        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    /* -------------------Map style-------------------------------------------------- */

    .block__map {
        display: flex;
        align-items: center;
        margin-top: 4vw;
        flex-direction: column;
    }

    .block__map span {
        font-size: 4vw;
        font-family: "Roboto";
        margin-bottom: 3vw;
    }

    .map__style {
        width: 90vw;
        height: 50vw;
        border-radius: 4vw;
        box-shadow: 0px 0px 3vw -1vw #00000040;
        border: 0.9vw solid var(--main-color);
        justify-content: center;
    }

    /* ----------------------------------Button style--------------------------- */

    .button__style {
        justify-content: center;
        margin-bottom: 7vw;
        margin-top: 0vw;
    }

    /* Logo стили */

    .about__logo {
        margin-bottom: 7vw;
    }

    .about__logo__img {
        width: 20vw;
        margin: 0 2vw;
    }
}