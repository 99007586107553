.style__button {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1.325vw;
    width: 5.5vw;
    height: 1.3vw;
    border-radius: 0.5vw;
    border-width: 0.20vw;
    border-style: solid;
    padding: 0 0.265vw;
    cursor: pointer;
    color: var(--main-color);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

}

.text__style {
    font-family: "Roboto";
    font-weight: 500;
    font-size: 0.9vw !important;
}

/* .style__button:active {
    border-color: var(--hover-color) !important;
    color: var(--hover-color);
} */

/* .style__button:hover {
    border-color: var(--hover-color) !important;
    color: var(--hover-color);
} */

@media (max-width: 770px) {
    .style__button {
        display: flex;
        justify-content: center !important;
        align-items: center !important;
        border-radius: 1.325vw;
        width: 24.15vw;
        height: 7vw;
        border-radius: 3.5vw;
        border-width: 0.9vw;
        border-style: solid;
        padding: 0 0.265vw;
        cursor: pointer;
        color: var(--main-color);
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .text__style {
        font-size: 3.7vw !important;
    }

    /* .style__button:hover {
        border-color: var(--main-color) !important;
        color: var(--main-color);
    }

    .style__button:active {
        border-color: var(--hover-color) !important;
        color: var(--hover-color);
    } */
}


@media (max-width: 992px) {}


@media (max-width: 767px) {}


@media (max-width: 650) {}


@media (max-width: 480px) {}