.events__create__style li {
    display: flex;
    list-style: none;
    margin-bottom: 1vw;
}

.events__create__style span {
    font-size: 0.9vw;
    /* border: 2px violet solid; */
    font-family: "Roboto";
}

.text__style {
    width: 7.2vw;
    height: 2vw;
    display: flex;
    align-items: center;
    /* border: 2px solid black; */
}

.h3__style {
    font-size: 1.2vw;
    font-family: "Roboto";
    font-weight: 500;
    display: flex;
    justify-content: center;
    margin-bottom: 7vw;
}

.btn__style {
    margin-left: 1vw;
    /* border: 2px solid red; */

}


@media (max-width: 770px) {
    .events__create__style ul {
        padding: 0;
        width: 100%;
    }

    .events__create__style li {
        display: flex;
        flex-direction: column;
        list-style: none;
        align-items: center;
    }

    .input__custom__style {
        width: 100% !important;
    }

    .btn__style {
        margin: 0;
        /* border: 2px solid red; */
        width: 100%;
        display: flex;
        justify-content: center;

    }

    .h3__style {
        font-size: 4vw;
        font-family: "Roboto";
        font-weight: 500;
        display: flex;
        justify-content: center;
        margin-bottom: 7vw;
    }

    .shadow__box__style {
        box-shadow: 0px 0px 3vw -1vw #00000040;
        padding: 4vw 2vw;
        padding-bottom: 3vw;
        border-radius: 4vw;
        margin-bottom: 4vw;

    }
}