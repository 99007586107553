.style__input__block {
    box-shadow: 0px 0px 0.12vw 0px #000040;
    width: 100%;
    height: 2.3vw;
    border-radius: 0.4vw;
    display: flex;
    padding: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    border: 0.1vw solid white;
}

.time {
    display: flex;
    align-items: center;
    justify-content: end;
    position: relative;
    /* border: 2px red solid; */
}

.time input {
    cursor: pointer;
    font: none;
    font-size: 1vw;
    font-family: "Roboto";
}

.timeIconCointainer {
    position: absolute;
    right: 0;
    z-index: -1;
    /* border: 2px red solid; */
}

.style__inputDate {
    border: 0px transparent solid;
    width: 100%;
    height: 1.5vw;
    border-radius: 1vw;
    outline: none;
    padding-left: 1vw;
    padding-right: 0.3vw;
    /* border: 2px red solid; */
}

.style__inputDate span {
    font-size: 1vw !important;
    font-weight: 500 !important;
    color: #242424;
    font-family: "Roboto";
}

.style__input {
    border: 0px transparent solid;
    width: 100%;
    height: 1.5vw;
    border-radius: 1vw;
    outline: none;
    font-size: 1vw;
    color: #242424;
    font-weight: 500;
    padding-left: 1vw;
    font-family: "Roboto";
    outline: none;
}

.style__input__block__image {
    display: flex;
    align-items: center;
    width: 2vw;
    justify-content: end;
    height: 100%;
    user-select: none;
    /* border: 2px red solid; */
}

.style__input__block__image img {
    display: block;
    padding-right: 0.3vw;
    width: 1vw;
}

.date {
    overflow: hidden;
    border: none;
    padding: 0;
    margin: 0;
    /* border: 2px red solid; */
}

.date::placeholder {
    content: "Дата";
}


.date span:nth-child(1) {
    height: 1.5vw;
    line-height: 1.4vw;
    border: none;
    /* border: 2px red solid; */
    padding: 0;
}



.select__container {
    width: 60vw;
    display: inline-flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.select__container:nth-child(1) {
    align-items: start;
}

.style__input__block:focus-within {
    /* box-shadow: 0px 0px 0.3vw 0px var(--hover-color); */
    border: 0.1vw solid var(--hover-color);
}


.inputBlock__lable{
    color: #808080;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 1vw;
    /* line-height: 1.4375em;
    letter-spacing: 0.00938em; */
    padding: 0px;
    display: block;
    transform-origin: left top;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 90%;
    position: absolute;
    left: 1.05vw;
    top: 0.6vw;
    transform: translate(0, 0) scale(1);
    transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    z-index: 2;
    pointer-events: none;
    /* border: 2px red solid; */
}

.inputBlock__lable__focus{
    color: #808080;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 0.8vw;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    padding: 0px;
    display: block;
    transform-origin: left top;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(133% - 32px);
    position: absolute;
    left: 0px;
    top: 0px;
    transform: translate(0.77vw, -0.5vw) scale(0.75);
    transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    z-index: 2;
    pointer-events: auto;
    user-select: none;
    background-color: white;
    padding: 0 0.4vw;
}

@media (max-width: 1280px) {
    .style__input {
        font-size: 1.35vw;
    }

    .style__inputDate {
        padding-left: 0.2vw;
    }

    .style__inputDate span {
        font-size: 1.35vw !important;
    }

    .date span:nth-child(1) {
        line-height: 1.5vw;
    }
}

@media (max-width: 770px) {

    .style__input__block:focus-within {
        border: 0.1vw solid var(--hover-color);
    }

    .style__input__block {
        box-shadow: 0px 0px 2vw -0.7vw #00000040;
        border: solid 0.2vw #bbbbbb;
        /* width: 100%; */
        height: 11vw;
        border-radius: 2vw;
        display: flex;
        padding: 0px;
        justify-content: space-between;
        align-items: center;
        background-color: white;
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
        -webkit-tap-highlight-color: transparent;
        /* border: 2px red solid; */
    }

    .style__input {
        border: 0px transparent solid;
        /* width: 100%; */
        height: 6vw;
        border-radius: 4vw;
        outline: none;
        font-size: 3.5vw;
        color: #242424;
        font-weight: 500;
        padding-left: 4vw;
        font-family: "Roboto";
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
        -webkit-tap-highlight-color: transparent;
        /* border: 2px red solid; */
    }

    .style__inputDate {
        border: 0px transparent solid;
        /* width: 100%; */
        height: 6vw;
        border-radius: 4vw;
        outline: none;
        padding-left: 4vw;
        font-family: "Roboto";
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
        -webkit-tap-highlight-color: transparent;
        /* border: 2px red solid; */

    }

    .style__inputDate {
        padding-left: 3.5vw;
        padding-right: 1vw;
    }

    .style__inputDate span {
        font-size: 3.5vw !important;
        font-weight: 500 !important;
        color: #242424;
    }

    .date span:nth-child(1) {
        height: 6vw;
        line-height: 6vw;
    }

    .select__container {
        /* width: 100% !important; */
        display: flex;
        flex-direction: column;
        align-items: end;
        /* border: 2px blue solid; */
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
        -webkit-tap-highlight-color: transparent;
        margin-bottom: 5vw;
    }

    .style__input__block__image {
        display: flex;
        align-items: center;
        width: 3vw;
        justify-content: end;
    }

    .style__input__block__image img {
        display: block;
        padding-right: 2vw;
        width: 3vw;
    }

    .time input {
        cursor: pointer;
        font: none;
        font-size: 3.5vw;
        font-family: "Roboto";
    }


    .inputBlock__lable{
       
        font-size: 3vw;
        left: 3vw;
        top: 4vw;
        transform: translate(0, 0) scale(1);
    }
    
    .inputBlock__lable__focus{
        font-size: 4vw;
        transform: translate(3vw, -2vw) scale(0.75);
        padding: 0 3vw;
    }

}